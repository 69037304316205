import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import {
  INTEGRATION_URL,
  EMBEDDED_SIGNUP_URL,
} from '@/assets/variables/endpoints'

export const state = () => ({
  integrationLists: [],
  integrationDetail: [
    {
      id: '',
      avatar: {
        default: true,
        icon: 'lab la-whatsapp',
      },
      whatsapp_name: '',
      user_token: '',
      server_base_url: '',
      webhook_url: '',
      ssl: false,
      phone_number: '',
      target_channel: '',
    },
  ],
  fbInformation: {},
  wabaLists: [],
  phoneNumberLists: [],
  whatsappIntegrationDetail: {
    id: null,
    settings: {
      account_name: null,
      server_wa_id: null,
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  getConnection({ commit, state, dispatch }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'content', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(`${INTEGRATION_URL}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            if (res.data.length) {
              commit('layouts/SET_META', res.meta, { root: true })
              commit('SET_DATA', res.data)
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getConnectionDetail({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const param = { target_channel: params.target_channel }
      requests.whatsapp
        .getService(`${INTEGRATION_URL}`, param, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            const connectionDetail = res.data.find(({ id }) => id === params.id)
            commit('UPDATE_DETAIL', connectionDetail)
            commit('SET_INTEGRATION_DETAIL', connectionDetail)
            resolve(res.data)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  connect({ commit, state }, params) {
    const domainsServer = params.server_base_url
    const userName = atob(params.user_token).split(':')[0]
    const userPassword = atob(params.user_token).split(':')[1]
    const accountName = params.whatsapp_name

    const param = {
      domain_server: domainsServer,
      username: userName,
      password: userPassword,
      account_name: accountName,
    }

    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/wa`, param, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_META', res.meta, { root: true })
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully Connected!',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'redirectToWhatsappChannel',
                },
              },
              { root: true }
            )
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  connectWaCloud({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${INTEGRATION_URL}/wa_cloud`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_META', res.meta, { root: true })
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err.error.messages[0].error.error_data.details)
          }
        )
    })
  },
  disconnect({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${INTEGRATION_URL}/${params.id}/status`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  remove({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      resolve(true)
      requests.whatsapp
        .deleteService(`${INTEGRATION_URL}/${params.id}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getWabaLists({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${EMBEDDED_SIGNUP_URL}/waba_ids`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_WABA_LISTS', res.data)
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  getPhoneNumberLists({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${EMBEDDED_SIGNUP_URL}/phone_numbers`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_PHONE_NUMBERS', res.data)
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  doEmbedSignup({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .postService(`${EMBEDDED_SIGNUP_URL}/waba`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('layouts/SET_META', res.meta, { root: true })
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'content', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  // getUnfinishedWABALists({ commit, state }, params) {
  //   return new Promise((resolve, reject) => {
  //     requests.whatsapp.getService(`${EMBEDDED_SIGNUP_URL}/list`, {}, { Authorization: this.$auth.getToken('hub') }).subscribe(
  //       (res) => {
  //         commit('layouts/SET_META', res.meta, { root: true })
  //         commit('SET_UNFINISHED_DATA', res.data)
  //         commit('layouts/UPDATE_CONTENTLOADING', { type: 'content', status: false }, { root: true })
  //         resolve(true)
  //       },
  //       (err) => {
  //         commit(
  //           'layouts/UPDATE_NOTIFICATION',
  //           {
  //             display: true,
  //             type: 'failed',
  //             message: 'Error!',
  //             item: err.error.messages[0],
  //             callback: {
  //               text: 'OK',
  //               method: 'closeNotification'
  //             }
  //           },
  //           { root: true }
  //         )
  //         commit('layouts/UPDATE_CONTENTLOADING', { type: 'content', status: false }, { root: true })
  //         reject(err)
  //       }
  //     )
  //   })
  // },
  requestServer({ commit, state }, params) {
    commit('UPDATE_LIST_LOADING', { ...params, status: true })
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${EMBEDDED_SIGNUP_URL}/list`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_LIST_LOADING', { ...params, status: false })
            resolve(true)
          },
          (err) => {
            commit('UPDATE_LIST_LOADING', { ...params, status: false })
            reject(err)
          }
        )
    })
  },
  checkWaType({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${INTEGRATION_URL}`, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_DATA(state, payload) {
    const data = []
    payload.forEach((list) => {
      data.push({
        ...list,
        is_finish: true,
        isLoading: false,
        isRequestedOTP: false,
        status: 'finished',
      })
    })
    state.integrationLists = data
  },
  UPDATE_DETAIL(state, payload) {
    state.integrationDetail = [
      {
        id: payload.id,
        avatar: {
          default: true,
          icon: 'lab la-whatsapp',
        },
        whatsapp_name: payload.settings.account_name,
        user_token: payload.settings.authorization,
        server_base_url: payload.settings.domain_server,
        webhook_url: `${process.env.HUB_SERVICE_URL}/webhooks/v1/${window.$nuxt.$auth.user.organization_id}/wa/${payload.webhook}`,
        ssl: false,
        phone_number: payload.settings.phone_number,
        target_channel: payload.target_channel,
      },
    ]
  },
  UPDATE_FB_INFORMATION(state, payload) {
    state.fbInformation = payload
  },
  UPDATE_PHONE_NUMBERS(state, payload) {
    state.phoneNumberLists = payload
  },
  UPDATE_WABA_LISTS(state, payload) {
    state.wabaLists = payload
  },
  SET_UNFINISHED_DATA(state, payload) {
    state.integrationLists = []
    payload.forEach((integration) => {
      const data = {
        created_at: integration.created_at,
        id: integration.id,
        is_finish: integration.is_finish,
        isLoading: false,
        isRequestedOTP: false,
        organization_id: integration.organization_id,
        settings: {
          account_name: integration.waba_name,
          authorization: integration.certificate,
          domain_server: null,
          server_wa_id: null,
        },
        status: 'need_verification',
        target_channel: 'wa',
        webhook: null,
      }
      state.integrationLists.push(data)
    })
  },
  UPDATE_LIST_LOADING(state, payload) {
    const index = _.findIndex(state.integrationLists, { id: payload.id })
    state.integrationLists[index].isLoading = payload.status
  },
  SET_INTEGRATION_DETAIL(state, payload) {
    state.whatsappIntegrationDetail = payload
  },
}
